.auth {
  background: url("./assets/img/mb_secure_background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth .input-fields input {
  margin-top: 8px;
}

.auth .submit {
  cursor: pointer;
}

#login .fa-spinner {
  font-size: 20px;
  margin: auto;
}

#login #auth-failed {
  color: #fff;
  font-weight: 800;
  font-size: 26px;
}

#signup.auth .welcome-texts,
#verify-otp.auth .welcome-texts {
  margin-bottom: 35px;
}

#set-new-pin .welcome-texts p {
  color: #70bf51;
}

#verify-otp.auth .otp-fields {
  margin: auto;
}

#verify-otp.auth .otp-fields input {
  width: 100% !important;
  padding: 14px;
}

#verify-otp.auth .submit:after {
  content: "verify";
}

#set-new-pin.auth .submit:after {
  content: "set password";
}

#login.auth .submit:after {
  content: "Sign In";
}

.auth .welcome-texts {
  text-align: center;
}

.auth .welcome-texts h3 {
  color: #70bf51;
}

.auth form {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
}

.auth form input:not(#verify-otp) {
  width: 100%;
  border-radius: 4px;
  padding: 10px 36px;
  border: 1px solid #70bf51;
  color: #195e07;
  opacity: 1;
  transition: opacity 0.2;
}

.auth .input-fields > div img {
  width: 18px;
}

.auth .input-fields > div i,
.auth .input-fields > div img {
  position: absolute;
  font-size: 20px;
  color: #70bf51;
  bottom: 9px;
  left: 10px;
}

.auth .input-fields > div {
  position: relative;
  margin: 10px 0;
  font-weight: 600;
}

.auth .submit.loading {
  opacity: 0.3;
}

.auth .submit:after {
  content: "submit";
}

.auth .submit.loading:after {
  content: "Loading...";
}

.auth form button {
  width: 100%;
  padding: 12px;
  margin: 20px 0;
  color: #fff;
  background-color: #195e07;
  font-weight: 600;
  text-transform: capitalize;
}

.auth .alternate-links {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
}

.auth .alternate-links a {
  color: #195e07;
  font-weight: 600;
}

#forgot-pin.auth .submit:after {
  content: "reset";
}

#forgot-pin.auth .welcome-texts {
  color: #195e07;
  font-weight: 600;
  margin-bottom: 26px;
}
